import { Injectable } from '@angular/core';
import {createAction, props} from "@ngrx/store";


@Injectable()
export class AppActions {
  static SET_USER = createAction('[App] Set user', props<{
    username: string,
    token: string,
    firstName: string,
  }>());

  static CLEAR_USER = createAction('[App] Clear user');

  static SELECT_REPORT = createAction('[App] Select Report', props<{
    id: string,
  }>());

  static SET_NETWORK_STATUS = createAction('[App] Update network status', props<{
    available: boolean
  }>());

  static REHYDRATED = createAction('NgrxStoreIdb/Rehydrate');
}

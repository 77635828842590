import {Component} from '@angular/core';
import {AppState} from "../+state/app/app.state";
import {Store} from "@ngrx/store";
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {getNetworkStatus} from "../+state/app/app.selectors";
import {MatSnackBar} from "@angular/material/snack-bar";
import {uploadQueue} from "../+state/file/file.selectors";
import {FileState} from "../+state/file/file.state";


@UntilDestroy()
@Component({
  selector: 'app-network-status',
  templateUrl: './network-status.component.html',
  styleUrls: ['./network-status.component.scss']
})
export class NetworkStatusComponent {

  available = true;

  pendingFiles = 0;

  constructor(
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private fileStore: Store<FileState>,
  ) {
    this.store.select(getNetworkStatus).pipe(untilDestroyed(this)).subscribe(network => {
      this.available = network.available;
    });

    this.fileStore.select(uploadQueue).pipe(untilDestroyed(this)).subscribe(files => {
      if (files) {
        this.pendingFiles = files.length;
      }
    });
  };

  showStatus() {
    this.snackBar.open(
      `You are currently offline. Documents will not sync until network access is restored.`,
      `\u00D7`,
      {
        duration: 5000,
        panelClass: ['app-notification-info']
      },
    );
  }

  showPending() {
    if (this.pendingFiles) {
      this.snackBar.open(
        `You have ${this.pendingFiles} file${this.pendingFiles > 1 ? 's' : ''} uploading.`,
        `\u00D7`,
        {
          duration: 5000,
          panelClass: ['app-notification-info']
        },
      );
    }
  }

}

import {Store} from '@ngrx/store';

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {switchMap, tap} from 'rxjs/operators';
import {AppState} from "../app/app.state";
import {AppActions} from "./app.actions";
import {ReportActions} from "../report/report.actions";
import {FileService} from "../file/file.service";
import {Observable, of} from "rxjs";

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    public store: Store<AppState>,
    public reportStore: Store<Report[]>,
    public service: FileService,
  ) {}

  selectReport$ = createEffect(() => this.actions$.pipe(
    ofType(AppActions.SELECT_REPORT),
    switchMap((payload): Observable<any> => {
        this.reportStore.dispatch(ReportActions.FETCH_REPORT({id: payload.id}))
        return of();
    })),
  );
}

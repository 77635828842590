import {APP_INITIALIZER, ErrorHandler, isDevMode, NgModule} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Router} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import * as Sentry from '@sentry/angular-ivy';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {ItemNumberModalComponent} from './item-number-modal/item-number-modal.component';
import {ItemSummaryComponent} from './item-summary/item-summary.component';
import {AuthComponent} from './auth/auth.component';
import {CameraComponent} from './camera/camera.component';
import {GalleryComponent} from './gallery/gallery.component';
import {AuthInterceptor} from './auth/auth.interceptor';
import {AuthService} from './auth/auth.service';
import {SentryErrorHandler} from './auth/sentry-error-handler';

import {environment} from '../environments/environment';

import {GalleriaModule} from 'primeng/galleria';
import {CarouselModule} from 'primeng/carousel';
import {CustomSnackbarComponent} from './custom-snackbar/custom-snackbar.component';
import {StateModule} from "./+state/state.module";
import {NetworkStatusComponent} from "./network-status/network-status.component";
import {MessageDialogComponent} from "./message-dialog/message-dialog.component";
const packageInfo = require('../../package.json');

if (!['local', 'development'].includes((environment as any).NAME)) {
  let version = `${packageInfo.version}${(environment.NAME !== 'production') ? '-dev' : ''}`;

  const release = `photo-capture@${version}`;
  Sentry.init({
    dsn: 'https://4d6fb42a1f1ef2867f1d94f840e32d2b@o4506400343851008.ingest.sentry.io/4506418430410752',
    release,
    environment: environment.NAME.toLowerCase(),
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          environment.API,
        ],
      }),
      Sentry.browserTracingIntegration(),
      Sentry.metrics.metricsAggregatorIntegration(),
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.Replay(),
      new Sentry.Feedback({
        autoInject: false,
        showBranding: false,
        showEmail: false,
        isNameRequired: true,
        buttonLabel: "Share Feedback",
        submitButtonLabel: "Send Feedback",
        formTitle: "Share Feedback",
        messagePlaceholder: "Please share your feedback.",
        successMessageText: "Thank you for your feedback!"
      }),
    ],
    autoSessionTracking: true,
    routingInstrumentation: Sentry.routingInstrumentation,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,

    tracesSampleRate: 0.1,
    tracePropagationTargets: ['localhost', /^https:\/\/api\.onsceneservices\.com/, /^https:\/\/dev-api\.onsceneservices\.com/],

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
  } as any);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ItemNumberModalComponent,
    ItemSummaryComponent,
    CameraComponent,
    GalleryComponent,
    AuthComponent,
    CustomSnackbarComponent,
    NetworkStatusComponent,
    MessageDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    HttpClientModule,
    StateModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MatSelectModule,
    HammerModule,
    GalleriaModule,
    CarouselModule,
  ],
  providers: [
    AuthInterceptor,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: Sentry.TraceService, deps: [Router], },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

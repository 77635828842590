import {ImageInfo} from "../../constants";

export interface FileState {
  file: {
    newUploadQueue: string[];
  }
}

export const initialFileState = {
  newUploadQueue: [],
};

<div class="header">
    <button mat-icon-button class="logout-button" (click)="logout()">
        <mat-icon>logout</mat-icon>
    </button>
    <span class="username">{{ this.firstName || this.currentUser }}</span>
    <span>
      <app-network-status></app-network-status>
      <button mat-icon-button class="feedback-button" (click)="giveFeedback()">
          <mat-icon>comment</mat-icon>
      </button>
    </span>
</div>

<div class="report-list">
    <div class="report-item" *ngFor="let report of reports" (click)="goToItemSummary(report)">
        {{ report }}
        <mat-icon class="arrow">navigate_next</mat-icon>
    </div>
</div>

<div class="new-report">
    <button (click)="openItemNumberModal()">NEW REPORT</button>
</div>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {Report} from "../../constants";
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  token: any = null;
  user: any = {};

  constructor(
    private http: HttpClient,
    ) {}

  listReports(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.API}/oss/media/reports`);
  }

  getReport(id: string): Observable<Report> {
    return this.http.get<Report>(`${environment.API}/oss/media/report?name=${id}`);
  }

  renameFolder(oldItemNumber: string, newItemNumber: string): Observable<any> {
    let reqBody = {
      oldItemNumber,
      newItemNumber
    };
    return this.http.patch(`${environment.API}/oss/media/renameFolder`, reqBody, { responseType: 'text' });
  }
}

import {Group, Report} from "../../constants";

export const newReportGroupState: Group = {
  title: '',
  documents: [],
  photos: [],
};

export const defaultGroups = {
  'scene': {
    ...newReportGroupState,
    title: 'Scene',
  },
  'd1': {
    ...newReportGroupState,
    title: 'Driver 1',
  },
  'd2': {
    ...newReportGroupState,
    title: 'Driver 2',
  },
}

export const newReportState: Report = {
  id: '',
  groups: {...defaultGroups},
}

import { createSelector } from '@ngrx/store';
import {Group, ImageInfo, Report} from "../../constants";
import {getSelectedReportId, getUser} from "../app/app.selectors";


export const listReports = (state: any) => state.reports;


export const listReportsForUser = createSelector(
  getUser,
  (state: any) => state.reports,
  (user: any, reports: Report[]) => {
    if (!user || user.username === undefined) {
      return [];
    }

    return reports.filter(report => report.userId === user.username);
  }
);

export const listReportIds = createSelector(listReportsForUser,
  (e: Report[]) => e.map((e2: Report) => e2.id)
);


export const findReportById = (id: string) => createSelector(listReportsForUser,
    (e: Report[]) => e.find((e2: Report) => e2.id === id)
);

export const getSelectedReport = createSelector(
  listReportsForUser,
  getSelectedReportId,
  (reports: Report[], selectedReportId): Report | undefined => {
    const report = reports.find(report => report.id === selectedReportId);

    if (!report) {
      return;
    }

    return {
      ...report,
    };
  }
);

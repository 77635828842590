<div #container class="video-container" id="video-container" (pinch)="handlePinch($event)">
  <video #videoElement autoplay></video>
  <div class="back-button-container">
    <button class="back-button" mat-fab (click)="goBack()">
      <mat-icon>check</mat-icon>
    </button>
  </div>
  <div class="button-container">
    <button class="capture-button" mat-fab (click)="capture()">
      <mat-icon class="capture-button-icon">photo_camera</mat-icon>
    </button>
    <button class="gallery-button" mat-fab (click)="fileInput.click()">
      <mat-icon>photo_library</mat-icon>
    </button>
  </div>
  <div class="progress-container" *ngIf="isZoomChanging">
    <progress id="zoomProgress" [value]="zoomLevel - 1" max="2"></progress>
  </div>
  <input type="file" #fileInput (change)="onFilesSelected($event)" multiple accept="image/jpg, image/jpeg, image/png, image/gif, image/bmp" style="display: none;">
  <canvas #canvas style="display: none;"></canvas>
  <div #messageContainer style="position: absolute"></div>

</div>

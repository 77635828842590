import { StorageDriver } from './storage-driver';
import { ImageInfo } from '../../../constants';
import Dexie, { IndexableType } from 'dexie';
import * as Sentry from "@sentry/angular-ivy";
import {SentryErrorHandler} from "../../../auth/sentry-error-handler";

export class NavigatorStorageDriver implements StorageDriver {
  private db: Dexie;

  constructor(
    private sentryErrorHandler: SentryErrorHandler,
  ) {
    this.db = new Dexie('OSSFiles');
    this.db.version(1).stores({
      files: 'key'
    });
    this.setPersist();
  }

  async saveFile(file: ImageInfo, data: Blob): Promise<IndexableType> {
    try {
      const root = await navigator.storage.getDirectory();
      const fileHandle = await root.getFileHandle(file.name!, { create: true });

      if ('createWritable' in fileHandle) {
        const writable = await fileHandle.createWritable();
        await writable.write(data);
        await writable.close();
      } else {
        const error = 'createWritable is not supported in this environment.';
        throw new Error(error)
      }
    } catch (e) {
      console.error('ERROR SAVING FILE');
      this.sentryErrorHandler.handleError(e);
    }
    return this.db.table('files').put(file, file.key);
  }

  async removeFile(file: ImageInfo): Promise<void> {
    try {
      const root = await navigator.storage.getDirectory();
      await root.removeEntry(file.name!);
    } catch (e) {
      console.error('ERROR DELETING FILE');
      this.sentryErrorHandler.handleError(e);
    }
    await this.db.table('files').delete(file.name!);
  }

  async getFile(key: string): Promise<ImageInfo | undefined> {
    try {
      const file = await this.db.table('files').get(key);
      if (!file) return undefined;

      const root = await navigator.storage.getDirectory();
      const fileHandle = await root.getFileHandle(file.name!);
      const blob = await fileHandle.getFile();

      return new Promise<ImageInfo>((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = (e: any) => {
          file['data'] = e.target.result;
          resolve(file);
        };

        fileReader.onerror = (error) => {
          this.sentryErrorHandler.handleError(error);
          reject(error);
        };

        fileReader.onabort = () => {
          const error = new Error('File load aborted');
          this.sentryErrorHandler.handleError(error);
          reject(error);
        };

        fileReader.readAsDataURL(blob);
      });
    } catch (e) {
      this.sentryErrorHandler.handleError(e);
      return undefined;
    }
  }


  private async setPersist() {
    const pr = navigator.storage && navigator.storage.persist &&
      await navigator.storage.persist();
    console.log(`Set persist: ${pr}`);

    const r = await this.checkPersisted();
    console.log(`Persisted: ${r}`);
    return r;
  }

  private async checkPersisted() {
    return await navigator.storage && navigator.storage.persisted &&
      navigator.storage.persisted();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ItemSummaryComponent } from './item-summary/item-summary.component';
import { CameraComponent } from './camera/camera.component';
import { AuthComponent } from './auth/auth.component';
import { GalleryComponent } from "./gallery/gallery.component";


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'item-summary', component: ItemSummaryComponent},
  { path: 'camera/:type1/:type2', component: CameraComponent},
  { path: 'gallery/:item', component: GalleryComponent},
  { path: 'login', component: AuthComponent},
  { path: '**' , redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import {appReducer} from "./app/app.reducer";
import {fileReducer} from "./file/file.reducer";
import {NgModule} from "@angular/core";
import {StoreModule} from "@ngrx/store";
import {reportReducer} from "./report/report.reducer";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../../environments/environment.development";
import {NgrxStoreIdbModule} from "ngrx-store-idb";
import {EffectsModule} from "@ngrx/effects";
import {ReportEffects} from "./report/report.effects";
import {FileEffects} from "./file/file.effects";
import {AppEffects} from "./app/app.effects";
import {SentryErrorHandler} from "../auth/sentry-error-handler";


@NgModule({
  imports: [
    StoreModule.forRoot({
        app: appReducer,
        file: fileReducer,
        reports: reportReducer,
      },
    ),
    EffectsModule.forRoot([
      AppEffects,
      ReportEffects,
      FileEffects,
    ]),
    NgrxStoreIdbModule.forRoot({
      keys: [
        'app',
        'file',
        'reports',
      ],
      rehydrate: true,
      saveOnChange: true,
      concurrency: {
        allowed: true,
        // Chrome's indexdb is based on leveldb which  always create new records when updating
        // this will appear to cause data to continuously grow but will be periodically compacted and cleaned up.
        refreshRate: 300,
        failInitialisationIfNoLock: false,
      },
      debugInfo: false,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.NAME === 'production',
    }),
  ],
})
export class StateModule { }

<div *ngIf="!showConfirmModal; else ConfirmModal">
	
	<h2 mat-dialog-title>New Item Number</h2>

	<mat-dialog-content class="vertical-align">
	  <div class="center-content">
	  	<mat-select class="prefix-suffix" [(value)]="selectedMonth">
	  	  <mat-option class="options" *ngFor="let month of months" [value]="month">
	  	    {{ month }}
	  	  </mat-option>
	  	</mat-select>
	  	<span class="hyphen">-</span>

	  	<mat-form-field class="input-field" color="accent">
	  	  <input matInput type="text" pattern="[0-9]*" inputmode="numeric" [(ngModel)]="inputValue" maxlength="5">
	  	</mat-form-field>

	  	<span class="hyphen">-</span>
	  	<mat-select class="prefix-suffix" [(value)]="selectedYear">
	  	  <mat-option class="options" *ngFor="let year of years" [value]="year">
	  	  	{{ year }}
	  	  </mat-option>
	  	</mat-select>
	  </div>

	  <div class="validation-error" *ngIf="errFiveDigits">
	  	<mat-icon style="margin-right: 10px" fontIcon="error"></mat-icon>Please enter five integers.
	  </div>
	  <div class="validation-error" *ngIf="errDup">
	  	<mat-icon style="margin-right: 10px" fontIcon="error"></mat-icon>This item already exists.
	  </div>
	</mat-dialog-content>

  	<mat-dialog-actions>  
  	  <div class="left-button-container">
  	    <button class="left-button" mat-dialog-close>CANCEL</button>
  	  </div>

  	  <div class="right-button-container">
  	    <button class="right-button" (click)="firstCheck()">NEXT</button>
  	  </div>
  	</mat-dialog-actions>
  	
</div>

<ng-template #ConfirmModal>
	
	<h2 mat-dialog-title>Item Number</h2>
	
	<mat-dialog-content>
		<div class="center-content">
			<h1 class="item-number">{{itemNumber}}</h1>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions> 
	  <div class="left-button-container">
	  	<button class="left-button" (click)="editButton()">EDIT</button>
	  </div>

	  <div class="right-button-container">
	  	<button class="right-button" *ngIf="!confirmProcessing" (click)="confirmItem()">CONFIRM</button>
	  	<mat-progress-spinner mode="determinate" *ngIf="confirmProcessing" [value]="loadingProgress" [diameter]="40" style="margin-left: 100px"></mat-progress-spinner>
	  </div>
	</mat-dialog-actions>

</ng-template>

import { Injectable } from '@angular/core';
import {createAction, props} from "@ngrx/store";
import {HttpErrorResponse} from "@angular/common/http";
import {User, Report} from "../../constants";


@Injectable()
export class ReportActions {
  static FETCH_REPORTS = createAction('[Report] Fetch reports...');
  static FETCH_REPORTS_SUCCESS = createAction('[Report] Fetched reports', props<{
    reports: string[],
    user: User,
  }>());
  static FETCH_REPORTS_ERROR = createAction('[Report] Unable to fetch reports', props<{error: HttpErrorResponse}>());

  static FETCH_REPORT = createAction('[Report] Fetch report...', props<{id: string}>());
  static FETCH_REPORT_SUCCESS = createAction('[Report] Fetched report', props<{report: Report}>());
  static FETCH_REPORT_ERROR = createAction('[Report] Unable to fetch reports', props<{error: HttpErrorResponse}>());

  static RENAME_REPORT = createAction('[Report] Renaming report...', props<{oldItemNumber: string, newItemNumber: string}>());
  static RENAME_REPORT_SUCCESS = createAction('[Report] Renamed report', props<{report: Report}>());
  static RENAME_REPORT_ERROR = createAction('[Report] Unable to rename reports', props<{
    oldItemNumber: string,
    newItemNumber: string,
    error: HttpErrorResponse,
  }>());

  static CREATE_REPORT = createAction('[Report] Create report', props<{id: string, userId: string}>());
  static CREATE_DRIVER = createAction('[Report] Create driver', props<{id: string}>());
  static REMOVE_DRIVER = createAction('[Report] Remove driver', props<{id: string}>());
}

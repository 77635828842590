import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from 'src/environments/environment';
import {MatSnackBar} from "@angular/material/snack-bar";
import {of} from "rxjs";
import {AppActions} from "./+state/app/app.actions";
import {Store} from "@ngrx/store";
import {AppState} from "./+state/app/app.state";
import {getUser} from "./+state/app/app.selectors";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  token: any = null;
  user: any = {};

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    ) {
    this.store.select(getUser).subscribe((user: any) => {
      this.token = user ? user.token : undefined;
    })
  }

  login(username:string, password:string): Observable<any> {
    return new Observable((observer) => {
      let postData = {username: username, password: password};
      this.http.post(environment.API + '/oss/accounts/signin', postData)
      .subscribe({next: (data:any) => {
        this.token = data[environment.TOKEN_KEY];
        this.user.username = data['username'];
        this.user.email = data['email'];
        this.user.roles = data['roles'];
        this.user.firstName = data['first'];
        observer.next({ user: this.user, token: this.token});
        observer.complete();
        return;
      },
      error: (err) => {
        if (err.status && err.status === 401) {
          this.snackBar.open(
            `The username or password is invalid, Please try again.`,
            `\u00D7`,
            {
              duration: 5000,
              panelClass: ['app-notification-error']
            },
          );
        } else {
          this.snackBar.open(
            `Unexpected error has occurred, Please try again.`,
            `\u00D7`,
            {
              duration: 5000,
              panelClass: ['app-notification-error']
            },
          );
        }

        observer.error(err);
        observer.complete();
        return;
      }})
    });
  }

  logout() {
    this.store.dispatch(AppActions.CLEAR_USER());
    return new Observable((observer) => {

      if(this.token != null) {
        this.http.post(`${environment.API}/common/accounts/signout?`, {})
        .subscribe({next: (resp) => {
          this.token = null;
          this.user = {};


          observer.next();
          observer.complete();
          return;
        },
        error: (err) => {
          this.snackBar.open(
            `Unexpected error has occurred, Please try again.`,
            `\u00D7`,
            {
              duration: 5000,
              panelClass: ['app-notification-error']
            },
          );
          observer.error(err);
          observer.complete();
          return;
        }});
      }
      else {
        observer.next();
        observer.complete();
        return;
      }
    });
  }

  isAuthenticated() {
    if (!this.token) {
      return of(false);
    }

    let headers = new HttpHeaders();
    if(this.token != null) headers = headers.set(environment.TOKEN_KEY, this.token);
    return this.http.get(`${environment.API}/common/accounts/checkToken`, {headers: headers});
  }

  uploadPhoto(path:string, name:string, data:string) {
    let reqBody = {
      path,
      name,
      data
    };
    return this.http.post(`${environment.API}/oss/media/photo`, reqBody);
  }

  ping() {
    return this.http.get(`${environment.API}/common/internalSystem/health`);
  }
}

import {Component, ElementRef, Inject, OnInit, Renderer2, RendererFactory2, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from "@angular/material/snack-bar";
import {concatMap, debounceTime, filter, Subject, Subscription, tap, timer} from 'rxjs';
import * as Hammer from 'hammerjs';


import {ApiService} from '../api.service';
import heic2any from "heic2any";
import {DOCUMENT} from "@angular/common";
import {CustomSnackbarComponent} from "../custom-snackbar/custom-snackbar.component";
import {Store} from "@ngrx/store";
import {AppState} from "../+state/app/app.state";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {getUser} from "../+state/app/app.selectors";
import {getSelectedReport} from "../+state/report/report.selectors";
import {FileActions} from "../+state/file/file.actions";
import {ImageInfo} from "../constants";
import * as Sentry from "@sentry/angular-ivy";
import {SentryErrorHandler} from "../auth/sentry-error-handler";


@UntilDestroy()
@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css'],
})
export class CameraComponent implements OnInit {
  @ViewChild('container') container!: ElementRef;
  @ViewChild('messageContainer') messageContainer!: ElementRef;
  @ViewChild('videoElement', { static: true }) videoElement!: ElementRef;
  @ViewChild('canvas') canvas!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;

  username = "";
  item_num = "";
  type1: string;
  type2: string;
  isZoomChanging: boolean = false;
  zoomLevel = 1;

  private pinchStartDistance = 0;
  private valueChange = new Subject<null>();
  private snackBarRef: any;
  private debounceSubscription: Subscription | null = null;
  private lastMessageTime = Date.now();
  private photoQueueSubject = new Subject<{dataUrl: string, fileName: string}[]>();
  // private photoQueueSubscription: Subscription;
  private snackbarMessageSubject = new Subject<{ message: string, panelClass: string }>();
  private snackbarMessageSubscription: Subscription;

  private get folderPath (): string {
    return `/${this.item_num}/${this.type1}/${this.type2}/`;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private renderer: Renderer2,
    private snackBar: MatSnackBar,
    private rendererFactory: RendererFactory2,
    private sentryErrorHandler: SentryErrorHandler,
    private store: Store<AppState>,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.store.select(getUser).pipe(filter(user => !!user), untilDestroyed(this)).subscribe(user => {
      this.username = user!.username;
    });

    this.store.select(getSelectedReport).pipe(filter(report => !!report), untilDestroyed(this)).subscribe(report => {
      this.item_num = report!.id;
    });

    this.type1 = this.route.snapshot.params['type1'];
    this.type2 = this.route.snapshot.params['type2'];

    // create a renderer so we can query the dom and add and remove classes to and from the snackbar container
    this.renderer = rendererFactory.createRenderer(null, null);

    // use concat map to ensure that the photo queue is processed in sequence
    // this.photoQueueSubscription = this.photoQueueSubject.pipe(concatMap((photos) => this.processPhotos(photos))).subscribe();

    // use concat map to ensure that the snackbar messages are displayed in sequence
    // use timer to ensure that the snackbar messages are displayed at least 750ms apart
    this.snackbarMessageSubscription = this.snackbarMessageSubject.pipe(concatMap(({ message, panelClass }) => {
      const timeElapsed = Date.now() - this.lastMessageTime;
      const delay = Math.max(750 - timeElapsed, 0);
      return timer(delay).pipe(tap(() => {
        this.openCustomSnackbar(message, panelClass);
        this.lastMessageTime = Date.now();
      }));
    })).subscribe();
  }

  ngOnInit() {
    this.startCamera();
  }

  ngAfterViewInit() {
    const hammer = new Hammer(this.videoElement.nativeElement);
    hammer.get('pinch').set({ enable: true });
    hammer.on('pinch', (event) => {
      // Handle pinch event
      this.handlePinch(event);
    });
  }

  ngOnDestroy() {
    this.stopCamera;
    // this.photoQueueSubscription?.unsubscribe();
    this.snackbarMessageSubscription?.unsubscribe();
  }

  goBack() {
    this.stopCamera();
    this.router.navigate(['/item-summary']);
  }

  // This is required to pin a snackbar message on a fullscreen element
  pinMessageToCanvas(snackBarRef: any) {
    const snackOverlay = (snackBarRef.containerInstance as any)['_elementRef'];
    this.messageContainer.nativeElement.appendChild(snackOverlay.nativeElement);
  }

  // Opens Camera
  async startCamera() {
    if (!navigator.mediaDevices) {
      this.snackBar.open(
        `Unable to access camera, please verify that it is enabled.`,
        `\u00D7`,
        {
          duration: 5000,
          panelClass: ['app-notification-error']
        },
      );
      return
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
          aspectRatio: 1.777777778, // 16:9 aspect ratio
          width: { ideal: 1920 }, // Needed to improve resolution
          height: { ideal: 1080 } // Needed to improve resolution
        },
      });
      this.videoElement.nativeElement.srcObject = stream;
      // this.container.nativeElement.requestFullscreen();
    } catch (error) {
      this.snackBar.open(
        `Unable to access camera, please verify that it is enabled.`,
        `\u00D7`,
        {
          duration: 5000,
          panelClass: ['app-notification-error']
        },
      );
      console.error('Error accessing the camera:', error);
      this.sentryErrorHandler.handleError(error);
    }
  }

  stopCamera() {
    if (
      this.videoElement.nativeElement &&
      this.videoElement.nativeElement.srcObject
    ) {
      this.videoElement.nativeElement.srcObject.getTracks().forEach((t:any) => {
        t.stop();
      });
    }
  }

  handlePinch(event: any) {
    // Set isZoomChanging to true when the pinch gesture starts
    this.isZoomChanging = true;
    const pinchType = event['additionalEvent'];

    if (event.velocity > 0.02) {
      if (pinchType === 'pinchout') {
        // Zoom in logic
        this.zoomIn(event.scale*0.002);
      } else if (pinchType === 'pinchin') {
        // Zoom out logic
        this.zoomOut(event.scale*0.005);
      }
      // Reset isZoomChanging to false when the pinch gesture ends
    }
    setTimeout(() => {
      this.isZoomChanging = false;
    }, 500);
  }

  zoomIn(scale : number) {
    const newZoom = this.zoomLevel * (1+scale); // Adjust the multiplier as needed
    this.updateZoom(newZoom);
  }

  zoomOut(scale : number) {
    const newZoom = this.zoomLevel / (1+scale); // Adjust the divisor as needed
    this.updateZoom(newZoom);
  }

  updateZoom(newZoom: number) {
    // Limit the zoom level within a reasonable range
    if (newZoom >= 1 && newZoom <= 3) {
      this.zoomLevel = newZoom;

      // Apply the zoom level by changing the CSS scale property
      this.renderer.setStyle(
        this.videoElement.nativeElement,
        'transform',
        `scale(${this.zoomLevel})`
      );
    }
  }

  // Takes picture
  async capture() {
    const video: HTMLVideoElement = this.videoElement.nativeElement;
    const canvas: HTMLCanvasElement = this.canvas.nativeElement;
    const context = canvas.getContext('2d');

    if (video && canvas && context) {
      // Calculate zoomed dimensions based on the current zoom level
      const zoomedWidth = video.videoWidth / this.zoomLevel;
      const zoomedHeight = video.videoHeight / this.zoomLevel;

      // Calculate the coordinates of the top-left corner of the zoomed-in region
      const startX = (video.videoWidth - zoomedWidth) / 2;
      const startY = (video.videoHeight - zoomedHeight) / 2;

      // Clear the canvas
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Calculate canvas dimensions based on the aspect ratio of the video feed
      const videoAspectRatio = video.videoWidth / video.videoHeight;
      let canvasWidth = 1080; // Default width
      let canvasHeight = 1920; // Default height

      if (videoAspectRatio > 1) {
        // Landscape orientation
        canvasWidth = 1920;
        canvasHeight = canvasWidth / videoAspectRatio;
      } else {
        // Portrait orientation
        canvasHeight = 1920;
        canvasWidth = canvasHeight * videoAspectRatio;
      }

      // Set canvas dimensions
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      // Draw the zoomed-in portion of the video frame onto the canvas
      context.drawImage(
        video,
        startX, startY, zoomedWidth, zoomedHeight,  // Source rectangle (zoomed region)
        0, 0, canvas.width, canvas.height           // Destination rectangle (canvas dimensions)
      );

      let d = canvas.toBlob((b) => {
        const data = b;

        if (data) {
          const photo = { dataUrl: data, fileName: `${this.username}_${Date.now()}.jpg` };
          const name = `${this.username}_${this.item_num}_${this.type1}_${this.type2}_${Date.now()}.jpg`;


          this.store.dispatch(FileActions.CREATE_FILE({
            file: {
              key: `${this.username}${this.folderPath}${name}`,
              reportId: this.item_num,
              group: this.type1,
              type: this.type2,
              data: undefined,
              status: 'new',
              name: name,
              path: this.folderPath,
            } as ImageInfo,
            data: data,
            group: this.type1,
          }));

          Sentry.metrics.increment("custom.UPLOAD", 1, {
            tags: {
              key: `${this.username}${this.folderPath}${name}`,
              reportId: this.item_num,
              source: 'camera',
              username: this.username,
            }
          });

          this.snackBar.open(
            `Image saved`,
            `\u00D7`,
            {
              duration: 1000,
              panelClass: ['app-notification-success']
            },
          );
        } else {
          this.snackBar.open(
            `Unable to capture image, please check permissions and try again.`,
            `\u00D7`,
            {
              duration: 3000,
              panelClass: ['app-notification-error']
            },
          );
        }
      }, 'image/jpeg');
    }
  }

  dataURLToB64String(dataURL: string): string {
    return dataURL.split(',')[1];
  }

  dataURLToBlob(dataURL: string): Blob {
    const base64String = dataURL.split(',')[1];
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'image/jpeg' });
  }

  async onFilesSelected(event: any): Promise<void> {
    const files: FileList = event.target.files;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        let selectedFile: File = files[i];

        if (selectedFile.type === "image/heic" || selectedFile.type === "image/heif") {
          selectedFile = await heic2any({
            blob: selectedFile,
            toType: "image/jpeg",
            quality: 1,
          }) as File;
        }

        const name = `${this.username}_${this.item_num}_${this.type1}_${this.type2}_${Date.now()}_${i}.jpg`;
        this.store.dispatch(FileActions.CREATE_FILE({
          file: {
            key: `${this.username}${this.folderPath}${name}`,
            reportId: this.item_num,
            group: this.type1,
            type: this.type2,
            data: undefined,
            status: 'new',
            name: name,
            path: this.folderPath,
          } as ImageInfo,
          group: this.type1,
          data: selectedFile
        }));

        Sentry.metrics.increment("custom.UPLOAD", 1, {
          tags: {
            key: `${this.username}${this.folderPath}${name}`,
            reportId: this.item_num,
            source: 'upload',
            username: this.username
          }
        });
      }
    }

    // Clear the file input value to allow selecting the same file again
    this.fileInput.nativeElement.value = '';
  }

  private openCustomSnackbar(message: string, panelClass: string): void {
    if (!this.snackBarRef) {
      this.snackBarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, { data: message });
    } else {
      this.snackBarRef.instance.data = message;
    }
    this.updateCustomSnackbarClass(panelClass);

    if (this.debounceSubscription) this.debounceSubscription.unsubscribe();
    // dismiss the snackbar after 5 seconds with no updates
    this.debounceSubscription = this.valueChange.pipe(debounceTime(5_000), filter(() => !!this.snackBarRef)).subscribe(() => {
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
    });
    // start the snackbar timer
    this.valueChange.next(null);

    this.pinMessageToCanvas(this.snackBarRef);
  }

  private updateCustomSnackbarClass(panelClass: string): void {
    const container = this.document.querySelector('.mat-mdc-snack-bar-container');
    if (!container) return;
    container.classList.remove('app-notification-error', 'app-notification-success', 'app-notification-info', 'app-notification-warning');
    this.renderer.addClass(container, panelClass);
  }
}

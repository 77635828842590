import { Injectable } from '@angular/core';
import {createAction, props} from "@ngrx/store";
import {DocumentType, ImageInfo, PhotoGroup} from "../../constants";
import {HttpErrorResponse} from "@angular/common/http";


@Injectable()
export class FileActions {
  static CREATE_FILE = createAction('[File] Create file', props<{
    file: ImageInfo,
    data: Blob,
    group: string,
  }>());

  static UPLOAD_FILE = createAction('[File] Uploading file...', props<{
    file: ImageInfo
    group: string,
  }>());
  static UPLOAD_FILE_SUCCESS = createAction('[File] Uploaded file', props<{file: ImageInfo}>());
  static UPLOAD_FILE_ERROR = createAction('[File] Unable to upload file', props<{file: ImageInfo; error: HttpErrorResponse}>());
}

import {createReducer, on} from "@ngrx/store";
import {FileActions} from "./file.actions";
import {initialFileState} from "./file.state";


export const fileReducer = createReducer(initialFileState,
  on(FileActions.CREATE_FILE, (state: any, {file}) => {
    const newUploadQueue = [file.key, ...state.newUploadQueue];

    return {
      ...state,
      newUploadQueue: newUploadQueue,
    }
  }),

  on(FileActions.UPLOAD_FILE_SUCCESS, (state: any, {file}) => {
    return {
      ...state,
      newUploadQueue: [
        ...state.newUploadQueue.filter((key: string) => key !== file.key),
      ],
    }
  }),

  // don't remove failed uploads
  on(FileActions.UPLOAD_FILE_ERROR, (state: any, {file}) => {
    return {
      ...state,
      newUploadQueue: [
        ...state.newUploadQueue.filter((key: string) => key !== file.key),
      ],
    }
  }),
)

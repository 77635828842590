import {Store} from '@ngrx/store';

import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap, withLatestFrom, filter, tap, catchError, delay} from 'rxjs/operators';
import {AppState} from "../app/app.state";
import {ReportActions} from "./report.actions";
import {HttpErrorResponse} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {ReportService} from "./report.service";
import {getUser} from "../app/app.selectors";
import {Report} from "../../constants";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()
export class ReportEffects {
  constructor(
    private actions$: Actions,
    public store: Store<AppState>,
    public service: ReportService,
    private snackBar: MatSnackBar,
  ) {}

  fetchReports$ = createEffect(() => this.actions$.pipe(
    ofType(ReportActions.FETCH_REPORTS),
    withLatestFrom(this.store.select(getUser).pipe(
      filter(user => !!user),
    )),
    switchMap(([action, user]: any[]): Observable<any> =>
      this.service.listReports().pipe(
        map(reports => (ReportActions.FETCH_REPORTS_SUCCESS({reports, user}))),
        catchError((error: HttpErrorResponse) => of(ReportActions.FETCH_REPORTS_ERROR({error})))
      )
    ),
  ));

  fetchReport$ = createEffect(() => this.actions$.pipe(
    ofType(ReportActions.FETCH_REPORT),
    switchMap((payload: {id: string}): Observable<any> =>
      this.service.getReport(payload.id).pipe(
        map(report => (ReportActions.FETCH_REPORT_SUCCESS({report}))),
        catchError((error: HttpErrorResponse) => of(ReportActions.FETCH_REPORT_ERROR({error})))
      )
    ),
  ));

  renameReport$ = createEffect(() => this.actions$.pipe(
    ofType(ReportActions.RENAME_REPORT),
    switchMap((payload: {oldItemNumber: string, newItemNumber: string}): Observable<any> =>
      this.service.renameFolder(payload.oldItemNumber, payload.newItemNumber).pipe(
        map(report => (ReportActions.RENAME_REPORT_SUCCESS({report: {id: payload.newItemNumber}} as any))),
        catchError((error: HttpErrorResponse) => {
          this.snackBar.open(
            `Unable to rename report while offline.`,
            `\u00D7`,
            {
              duration: 5000,
              panelClass: ['app-notification-error']
            },
          );
          return of(ReportActions.RENAME_REPORT_ERROR({
            oldItemNumber: payload.oldItemNumber,
            newItemNumber: payload.newItemNumber,
            error,
          }));
        }))
      )
    ),
  );

  renameReportSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(ReportActions.RENAME_REPORT_SUCCESS),
      switchMap((payload): Observable<any> => {
        return of(ReportActions.FETCH_REPORT({
          id: payload.report.id,
        })).pipe(
          delay(1000), // delay to allow server to finish moving files
        )
      })
    ),
  );
}

import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";
import {User} from "../constants";
const CircularJSON = require('circular-json');;

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandler implements ErrorHandler {

  constructor(
  ) {}

  setUser(user: User | undefined) {
    Sentry.setUser(
      user ? {username: user.username} : null,
    );
  }

  handleError(error: any): void {
    SentryErrorHandler.handleError(error);
  }

  static handleError(error: any) {
    console.error(error);
    if (error) {
      if (error.originalError) {
        try {
          const errorString = CircularJSON.stringify(error.originalError);
          this.handleError(new Error(errorString));
        } catch (e) {
          Sentry.captureException('Unexpected Error');
        }
      } else if (error.error) {
        Sentry.captureException(error.error);
      } else if (error.message) {
        Sentry.captureException(error.message);
      } else if (typeof error === 'string') {
        Sentry.captureException(new Error(error));
      } else {
        Sentry.captureException(error);
      }
    }

    throw error;
  }
}

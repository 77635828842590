import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {Router} from '@angular/router';
import {ApiService} from "../api.service";
import {delay} from "../helpers";
import {SwUpdate} from "@angular/service-worker";
import {SentryErrorHandler} from "./sentry-error-handler";
import {getNetworkStatus, getUser} from "../+state/app/app.selectors";
import {AppState} from "../+state/app/app.state";
import {Store} from "@ngrx/store";
import * as Sentry from "@sentry/angular-ivy";
import {untilDestroyed} from "@ngneat/until-destroy";



const KEEP_ALIVE_LOOP_DELAY = 5 * 60 * 1000;
const OFFLINE_PING_DELAY = 3 * 1000;

@Injectable()
export class AuthService {

  private isAppUpdated = false;

  private isOffline = false;

  constructor(
    private router: Router,
    private api: ApiService,
    private swUpdate: SwUpdate,
    private store: Store<AppState>,
    private sentryErrorHandler: SentryErrorHandler,
    ) {
    this.keepAliveLoop();
    this.checkUpdateLoop();
    this.offlinePingLoop();

    this.store.select(getUser).subscribe((user) => {
      this.sentryErrorHandler.setUser(user);
      this.loginUpdateReload();
    });
    this.store.select(getNetworkStatus).subscribe(network => {
      this.isOffline = !network.available;
    });
  }

  async checkUpdateLoop() {
    if (this.swUpdate.isEnabled) {
      console.info('Service worker available');
      await this.checkUpdate(); // silently checks if update is available and refreshes on initial app load
      while (1) {
        try {
            await this.checkUpdate(false) // future update checks will set a flag which will force a refresh on login
            await delay(KEEP_ALIVE_LOOP_DELAY);
        } catch (e: any) {
          this.sentryErrorHandler.handleError(e);
        }
      }
    } else {
      console.info('Service worker is NOT available');
    }
  }

  async checkUpdate(silent = true) {
    console.info('Checking for update');
    const updateAvailable = await this.swUpdate.checkForUpdate()

    if (updateAvailable) {
      console.info('Update found, activating');
      const updateActivated = await this.swUpdate.activateUpdate();

      if (updateActivated) {
        console.log('Application updated and will restart on next login');
        this.isAppUpdated = true;
        if (!silent) {
          this.loginUpdateReload()
        }
      }
    }
  }

  loginUpdateReload() {
    if (this.isAppUpdated) {
      window.location.reload();
    }
  }

  async keepAliveLoop() {
    while (1) {
      await delay(KEEP_ALIVE_LOOP_DELAY);
      try {
        const authToken =  this.api.token;

        if (authToken) {
          await firstValueFrom(this.api.isAuthenticated());
        }
      } catch (e: any) {
        // dont care about errors
      }
    }
  }

  async offlinePingLoop() {
    while (1) {
      await delay(OFFLINE_PING_DELAY);

      if (this.isOffline) {
        try {
          await firstValueFrom(this.api.ping());
        } catch (e: any) {
          // dont care about errors
        }
      }
    }
  }
}

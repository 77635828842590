
export enum ImageStatus {
  NEW = 'new',
  UPLOADED = 'uploaded',
  ERROR = 'error',
}

export interface User {
  username: string,
  token: string,
  firstName: string,
};

export enum DocumentType {
  PHOTOS = 'photos',
  DOCUMENTS = 'documents',
};

export interface ImageInfo {
  key: string;
  reportId?: string;
  name?: string;
  path?: string;
  type: DocumentType;
  group: string;
  url: string;
  thumbnailUrl?: string;
  data?: string;
  status?: ImageStatus;
}

export interface DocGroup {
  type: string;
  groups: PhotoGroup[];
}

export interface PhotoGroup {
  label: string; // scene, d1, d2, etc.
  images: ImageInfo[]; // list of images
}

export interface Group {
  title: string;
  documents: ImageInfo[]  ;
  photos: ImageInfo[];
}

export interface Report {
  id: string;
  userId?: string;
  groups: { [groupName: string]: Group };
}


import {createReducer, on} from "@ngrx/store";
import {ReportActions} from "./report.actions";
import {defaultGroups, newReportGroupState, newReportState} from "./report.state";
import {DocumentType, ImageInfo, ImageStatus, Report} from "../../constants";
import {FileActions} from "../file/file.actions";


export const reportReducer = createReducer([] as Report[],
  on(ReportActions.FETCH_REPORTS_SUCCESS, (state, payload) => {
    let newState = [...state];
    for (let reportId of payload.reports) {
      const existingReportIndex = newState.findIndex(report => report.id === reportId);
      if (existingReportIndex === -1) {
        newState.push({
          ...newReportState,
          id: reportId,
          userId: payload.user.username,
        });
      } else {
        const existingReport = {...newState[existingReportIndex]};
        Object.assign(
          existingReport,
          {
          id: reportId,
          userId: payload.user.username,
        })

        newState[existingReportIndex] = existingReport;
      }
    }

    newState = sortObjectsById(newState, payload.reports);

    return newState;
  }),

  on(ReportActions.FETCH_REPORT_SUCCESS, (state, {report}) => {
    const newState = state.map((localReport: Report) => {
      if (localReport.id === report.id) {
        let updatedGroups = { ...localReport.groups };

        Object.keys(report.groups).forEach((groupKey: string) => {
          const localReportGroup = localReport.groups[groupKey];

          // Add group if it doesn't exist locally
          if (!localReportGroup) {
            updatedGroups[groupKey] = {
              ...newReportGroupState,
              title: titleFromId(report.groups[groupKey].title),
            };
          }

          // merge group if it exists
          const reportGroup = report.groups[groupKey];
          Object.values(DocumentType)
            .filter((type: DocumentType) => !!reportGroup[type])
            .forEach((type: DocumentType) => {
              const existingPhotos = Object.keys(reportGroup[type]).map((key) => (reportGroup[type] as any)[key]);

              const localPhotos = updatedGroups[groupKey][type];

              const existingPhotosObj = existingPhotos.reduce((acc, photo) => {
                acc[photo.key] = {
                  ...photo,
                  status: 'uploaded',
                };
                return acc;
              }, {});

              const localPhotosObj = localPhotos.reduce((acc: any, photo: ImageInfo) => {
                acc[photo.key] = photo;
                return acc;
              }, {});
              const mergedPhotosObj = {...localPhotosObj, ...existingPhotosObj};
              const mergedPhotosArray = Object.values(mergedPhotosObj) as ImageInfo[];

              updatedGroups[groupKey] = {...updatedGroups[groupKey]};
              updatedGroups[groupKey][type] = mergedPhotosArray;
            });
        });

        return {
          ...localReport,
          groups: updatedGroups,
        };
      } else {
        return localReport;
      }
    });

    return newState;
  }),

  on(ReportActions.CREATE_REPORT, (state, {id, userId}) => {
    const newReport = {
      ...newReportState,
      id,
      userId: userId
    }

    return [
      newReport,
      ...state,
    ];
  }),

  on(ReportActions.RENAME_REPORT, (state, { oldItemNumber, newItemNumber }) => {
    const updatedReports = [...state];

    return [...updatedReports.map((report) => {
      if (report.id === oldItemNumber) {
        return {
          ...report,
          id: newItemNumber,
        };
      }

      return report
    })];
  }),

  // revert the rename on failure
  on(ReportActions.RENAME_REPORT_ERROR, (state, { oldItemNumber, newItemNumber }) => {
    const updatedReports = [...state];

    return [...updatedReports.map((report) => {
      if (report.id === newItemNumber) {
        return {
          ...report,
          id: oldItemNumber,
        };
      }

      return report
    })];
  }),

  on(ReportActions.RENAME_REPORT_SUCCESS, (state, {report}) => {
    const updatedReports = [...state];

    return [...updatedReports.map((report) => {
      if (report.id === report.id) {
        return {
          ...report,
          groups: {...defaultGroups},// clear out images and reload new paths
        };
      }

      return report
    })];
  }),

  on(ReportActions.CREATE_DRIVER, (state, { id }) => {
    const updatedReports = state.map((report: Report) => {
      if (report.id === id) {
        const groupCount = Object.keys(report.groups).length;
        const nextDriverNumber = groupCount;
        let updatedGroups = { ...report.groups };

        if (nextDriverNumber > 0) {
          const nextDriverName = `Driver ${nextDriverNumber}`;
          const nextDriverKey = `d${nextDriverNumber}`;
          updatedGroups[nextDriverKey] = {
            ...newReportGroupState,
            title: nextDriverName,
          };
        }

        return { ...report, groups: updatedGroups };
      } else {
        return report;
      }
    });

    return updatedReports;
  }),

  on(ReportActions.REMOVE_DRIVER, (state, { id }) => {
    const updatedReports = state.map((report: Report) => {
      if (report.id === id) {
        let updatedGroups = { ...report.groups };

        const lastIndex =Object.keys(updatedGroups)[Object.keys(updatedGroups).length - 1];

        if (lastIndex !== 'scene') {
          delete updatedGroups[lastIndex];
        }

        return { ...report, groups: updatedGroups };
      } else {
        return report;
      }
    });

    return updatedReports;
  }),

  on(FileActions.CREATE_FILE, (state, payload) => {
    const updatedReports = state.map((report: Report) => {
      if (report.id === payload.file.reportId) {
        const updatedGroups = { ...report.groups };
        updatedGroups[payload.group] = {...updatedGroups[payload.group]};

        const file = {...payload.file};

        // dont save file data to ngrx
        delete file.data;

        updatedGroups[payload.group][payload.file.type] = [
          file,
          ...updatedGroups[payload.group][payload.file.type]
        ];

        return { ...report, groups: updatedGroups };
      } else {
        return report;
      }
    });

    return updatedReports;
  }),

  on(FileActions.UPLOAD_FILE_SUCCESS, (state: any, {file}) => {
    const updatedReports = state.map((report: Report) => {
      if (report.id === file.reportId) {
        const updatedGroups = { ...report.groups };
        const group = file.group;

        if (updatedGroups[group]) {
          updatedGroups[group] = {...updatedGroups[group]};
        } else {
          updatedGroups[group] = {
            ...newReportGroupState,
          title: group,
          };
        }


        if (updatedGroups[group]) {
          const type = file.type;
          if (!updatedGroups[group][type]) {
            updatedGroups[group][type] = [];
          }

          updatedGroups[group][type] = [
            ...updatedGroups[group][type]
          ].map((image: ImageInfo) => {
            if (image.key === file.key) {
              const updatedImage = {
                ...image,
                url: file.url,
                thumbnailUrl: file.thumbnailUrl,
                status: ImageStatus.UPLOADED
              }

              return updatedImage
            } else {
              return image;
            }
          }) as ImageInfo[];
        }


        return { ...report, groups: updatedGroups };
      } else {
        return report;
      }
    });

    return updatedReports;
    return {
      ...state,
      files: [...state.file.files].filter(file => file.key !== file.key),
      newUploadQueue: [
        ...state.file.newUploadQueue.filter((key: string) => key !== file.key),
      ],
    }
  }),

  on(FileActions.UPLOAD_FILE_ERROR, (state: any, {file}) => {
    const updatedReports = state.map((report: Report) => {
      if (report.id === file.reportId) {
        const updatedGroups = { ...report.groups };
        const group = file.group;
        updatedGroups[group] = {...updatedGroups[group]};

        if (updatedGroups[group]) {
          const type = file.type;
          updatedGroups[group][type] = [
            ...updatedGroups[group][type]
          ].map((image: ImageInfo) => {
            if (image.key === file.key) {
              const updatedImage = {
                ...image,
                status: ImageStatus.ERROR
              }

              return updatedImage
            } else {
              return image;
            }
          }) as ImageInfo[];
        }


        return { ...report, groups: updatedGroups };
      } else {
        return report;
      }
    });

    return updatedReports;
    return {
      ...state,
      files: [...state.file.files].filter(file => file.key !== file.key),
      newUploadQueue: [
        ...state.file.newUploadQueue.filter((key: string) => key !== file.key),
      ],
    }
  }),
)

function sortObjectsById(objects: any[], idList: string[]) {
  const idMap = new Map();
  idList.forEach((id, index) => {
    idMap.set(id, index);
  });

  return objects.sort((a, b) => {
    const indexA = idMap.has(a.id) ? idMap.get(a.id) : -1;
    const indexB = idMap.has(b.id) ? idMap.get(b.id) : -1;
    if (indexA > indexB) {
      return 1;
    } else if (indexA < indexB) {
      return -1;
    } else {
      return 0;
    }
  });
}


function titleFromId(inputString: string): string {
  if (inputString.startsWith('d') && !isNaN(inputString.substring(1) as any)) {
    return `Driver ${inputString.substring(1)}`;
  } else {
    return inputString;
  }
}

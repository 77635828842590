import { StorageDriver } from './storage-driver';
import { ImageInfo } from '../../../constants';
import Dexie, { IndexableType } from 'dexie';
import * as Sentry from "@sentry/angular-ivy";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import {SentryErrorHandler} from "../../../auth/sentry-error-handler";

export class PhoneStorageDriver implements StorageDriver {
  private db: Dexie;

  constructor(
    private sentryErrorHandler: SentryErrorHandler,
  ) {
    this.db = new Dexie('OSSFiles');
    this.db.version(1).stores({
      files: 'key'
    });
  }

  async testAvailability(): Promise<void> {
    try {
      await Filesystem.writeFile({
        path: 'fscheck.txt',
        data: 'fscheck',
        directory: Directory.Documents
      });
      await Filesystem.deleteFile({
        path: 'fscheck.txt',
        directory: Directory.Documents
      });
    } catch (e) {
      throw new Error('PhoneStorageDriver not available');
    }
  }

  async saveFile(file: ImageInfo, data: Blob): Promise<IndexableType> {
    try {
      const base64Data = await this.convertBlobToBase64(data);
      const path = `oss${file.path}`;
      await this.ensureDirectoryExists(path);
      await Filesystem.writeFile({
        path: `${path!}${file.name!}`,
        data: base64Data,
        directory: Directory.Documents
      });
      // Save metadata to the database
      const fileInfo = { ...file };
      delete fileInfo.data;
      return this.db.table('files').put(fileInfo, file.key);
    } catch (e) {
      console.error('PROBLEM SAVING FILE');
      this.sentryErrorHandler.handleError(e);
      throw e;  // rethrow the error to handle it properly
    }
  }

  private async ensureDirectoryExists(directoryPath: string): Promise<void> {
    try {
      await Filesystem.mkdir({
        path: directoryPath,
        directory: Directory.Documents,
        recursive: true
      });
    } catch (e: any) {
      if (e.message !== 'Directory already exists' && e.message !== 'Directory exists') {
        throw e;
      }
    }
  }

  async removeFile(file: ImageInfo): Promise<void> {
    // never remove the files from phone
    // try {
    //   const path = `oss${file.path!}${file.name!}`;
    //   await Filesystem.deleteFile({
    //     path: path!, // uses file name as the path
    //     directory: Directory.Documents
    //   });
    // } catch (e) {
    //   console.error('PROBLEM DELETING FILE');
    //   console.error(e);
    //   this.sentryErrorHandler.handleError(e);
    // }
    await this.db.table('files').delete(file.key);
  }

  async getFile(key: string): Promise<ImageInfo | undefined> {
    const fileMetadata = await this.db.table('files').get(key);
    if (fileMetadata) {
      try {
        const path = `oss${fileMetadata.path!}${fileMetadata.name!}`;
        const result = await Filesystem.readFile({
          path: path, // uses file name as the path
          directory: Directory.Documents,
        });
        const fileData = this.convertToDataURL(fileMetadata.name!, result.data as any);
        fileMetadata['data'] = fileData;
        return fileMetadata;
      } catch (e) {
        this.sentryErrorHandler.handleError(e);
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  private convertToDataURL(fileName: string, base64String: string): string {
    const extension = fileName.split('.').pop();
    const mimeType = this.getMimeType(extension);
    return `data:${mimeType};base64,${base64String}`;
  }

  private getMimeType(extension: string | undefined): string {
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      case 'webp':
        return 'image/webp';
      default:
        return 'application/octet-stream';
    }
  }
}

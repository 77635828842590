import { Component, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { BrowserClient, Feedback, getClient } from '@sentry/angular-ivy'

import { ItemNumberModalComponent } from '../item-number-modal/item-number-modal.component';
import {AppState} from "../+state/app/app.state";
import {Store} from "@ngrx/store";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {getSelectedReport} from "../+state/report/report.selectors";
import {DocumentType, Report, ImageStatus} from "../constants";
import {ReportActions} from "../+state/report/report.actions";
import { FileActions } from '../+state/file/file.actions';


@UntilDestroy()
@Component({
  selector: 'app-item-summary',
  templateUrl: './item-summary.component.html',
  styleUrls: ['./item-summary.component.css']
})
export class ItemSummaryComponent {
  DocumentType = DocumentType;

  report: Report | undefined;
  sceneCat: string = '';
  showCategoryModal: boolean = false;
  dialogRef!: MatDialogRef<any>;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private store: Store<AppState>,
  ) {
    this.store.select(getSelectedReport).pipe(untilDestroyed(this)).subscribe(report => {
      this.report = report;
      
      if (report) { // this code uploads all lagging photos that haven't been uploaded yet
        for (let group of Object.values(report.groups)) {
          for (let image of group.documents) {
            if (image.status == 'new') {
              this.store.dispatch(FileActions.UPLOAD_FILE({file: image, group: 'documents'}))
            }
          }
          for (let image of group.photos) {
            if (image.status == 'new') {
              this.store.dispatch(FileActions.UPLOAD_FILE({file: image, group: 'photos'}))
            }
          }
        }
      }
    });
  };

  getGroups() {
    return Object.keys(this.report ? this.report.groups : {})
      .map(key => (this.report!.groups[key].title))
  }

  // Back Button - goes to Home
  goBack() {
    this.router.navigate(['']);
  }

  editItemNumber() {
    const dialogConfig = new MatDialogConfig();

    // Create a custom position strategy to center the dialog
    dialogConfig.position = {
      top: '20%',
      left: '10%',
    };
    dialogConfig.width = '350px';
    dialogConfig.data = {
      editNumberModal : true,
      oldItemNumber : this.report!.id,
    }

    const dialogRef = this.dialog.open(ItemNumberModalComponent, dialogConfig);
  }

  // Opens Sentry feedback widget
  giveFeedback() {
    const client = getClient<BrowserClient>();
    const feedback = client?.getIntegration(Feedback);

    // Don't render custom feedback button if Feedback integration isn't installed
    if (!feedback) {
      console.log('Error: Feedback integration not available.')
      return null;
    }

    feedback.openDialog();
    return;
  }

  openCategoryModal(label: string, templateRef: TemplateRef<any>) {
    const dialogConfig = new MatDialogConfig();
    if (label == 'Scene') {
      this.sceneCat = 'scene';
    } else {
      this.sceneCat = 'd' + label.substring(label.length - 1);
    }
    // Create a custom position strategy to center the dialog
    dialogConfig.position = {
      top: '50%',
      left: '11%',
    };
    dialogConfig.width = '300px';

    this.dialogRef = this.dialog.open(templateRef, dialogConfig);
  }

  openCamera(category: string) {
    this.dialogRef.close();
    this.router.navigate(['/camera', this.sceneCat, category]);
  }

  addButton() {
    if (this.report) {
      this.store.dispatch(ReportActions.CREATE_DRIVER({id: this.report.id}))
    }
  }

  minusButton() {
    if (this.report) {
      this.store.dispatch(ReportActions.REMOVE_DRIVER({id: this.report.id}))
    }
  }

  galleryButton() {
    this.router.navigateByUrl(`/gallery/${this.report!.id}`);
  }
}

import {User} from "../../constants";

export interface AppState {
  app: {
    rehydrated: boolean,
    user?: User;
    selectedReportId?: string;
    network: {
      available: boolean,
    }
  }
}

export const initialState = {
  rehydrated: false,
  user: undefined,
  selectedReportId: undefined,
  network: {
    available: true,
  }
}

import {createReducer, on} from "@ngrx/store";
import {initialState} from "./app.state";
import {AppActions} from "./app.actions";
import {ReportActions} from "../report/report.actions";


export const appReducer = createReducer(initialState as any,
  on(AppActions.SET_USER, (state, action) => {
    return {
      ...state,
      user: action,
    }
  }),

  on(AppActions.SET_NETWORK_STATUS, (state: any, action) => {
    return {
      ...state,
      network: {
        ...state.network,
        available: action.available,
      },
    }
  }),

  on(AppActions.REHYDRATED, (state: any, action) => {
    return {
      ...state,
      rehydrated: true,
    }
  }),

  on(AppActions.CLEAR_USER, (state, action) => {
    return {
      ...state,
      user: undefined,
    }
  }),

  on(AppActions.SELECT_REPORT, (state, action) => {
    return {
      ...state,
      selectedReportId: action.id,
    }
  }),

  // revert the rename on failure
  on(ReportActions.RENAME_REPORT_ERROR, (state, { oldItemNumber, newItemNumber }) => {
    return {
      ...state,
      selectedReportId: oldItemNumber,
    }
  }),
)

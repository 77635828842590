import {Component, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {filter, Subject} from "rxjs";

import {MatSnackBar} from '@angular/material/snack-bar';
import {BrowserClient, Feedback, getClient} from '@sentry/angular-ivy'

import {ItemNumberModalComponent} from '../item-number-modal/item-number-modal.component';
import {ApiService} from '../api.service';
import {Report} from "../constants";
import {Store} from "@ngrx/store";
import {listReportsForUser} from "../+state/report/report.selectors";
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AppActions} from "../+state/app/app.actions";
import {getUser} from "../+state/app/app.selectors";
import {AppState} from "../+state/app/app.state";
import {ReportActions} from "../+state/report/report.actions";


@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnDestroy {
  reports: string[] = [];
  currentUser: string = ''; // Used in Header display
  firstName: string | null = null;

  private destroy: Subject<void> = new Subject<void>();

  constructor(private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private store: Store<AppState | Report[] | any>,
    private api: ApiService,
  ) {
    this.store.select(getUser).pipe(
      untilDestroyed(this),
      filter(user => !!user),
    ).subscribe(user => {
      this.currentUser = user!.username;
      this.firstName = user!.firstName;
      this.store.dispatch(ReportActions.FETCH_REPORTS())
    });
  };

  ngOnInit() {
    this.store.select(listReportsForUser)
      .pipe(untilDestroyed(this))
      .subscribe((reports: Report[]) => {
      this.reports = reports.map(report => report.id);
    });
  }

  // Existing Report Button
  goToItemSummary(itemNum: string) {
    this.store.dispatch(AppActions.SELECT_REPORT({ id: itemNum }));
    this.router.navigate(['/item-summary']);
  }

  // New Report Button
  openItemNumberModal() {
    const dialogConfig = new MatDialogConfig();

    // Create a custom position strategy to center the dialog
    dialogConfig.position = {
      top: '20%',
      left: '10%',
    };
    dialogConfig.width = '350px';

    this.dialog.open(ItemNumberModalComponent, dialogConfig);
  }

  // Opens Sentry feedback widget
  giveFeedback() {
    const client = getClient<BrowserClient>();
    const feedback = client?.getIntegration(Feedback);

    // Don't render custom feedback button if Feedback integration isn't installed
    if (!feedback) {
      console.log('Error: Feedback integration not available.')
      return null;
    }

    feedback.openDialog();
    return;
  }

  // Logout User
  logout() {
    this.api.logout();
    if (this.router.url !== '/login') {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
};

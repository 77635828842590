<div class="header">
	<button mat-icon-button class="back-button" (click)="goBack()">
		<mat-icon>arrow_back</mat-icon>
	</button>
  	<span>{{ this.report?.id }}</span>
	<button mat-icon-button class = "edit-button" (click)="editItemNumber()">
		<mat-icon>edit</mat-icon>
	</button>
  <app-network-status class="network-status"></app-network-status>
	<button mat-icon-button class="feedback-button" (click)="giveFeedback()">
    <mat-icon>comment</mat-icon>
  </button>
</div>

<div class="button-group">
  <button *ngFor="let label of getGroups()" (click)="openCategoryModal(label, CategoryModal)">
   	{{ label }}
  </button>
</div>

<div class="action-buttons">
  <button class="minus-button" (click)="minusButton()">
    <mat-icon class="icon">remove</mat-icon>
  </button>

  <button class="gallery-button" (click)="galleryButton()">
    <mat-icon class="icon">photo_library</mat-icon>
  </button>

 	<button class="plus-button" (click)="addButton()">
   	<mat-icon class="icon">add</mat-icon>
 	</button>
</div>

<ng-template #CategoryModal>
	<mat-dialog-content>
		<div>
	  		<button class="modal-button" (click)="openCamera(DocumentType.DOCUMENTS)">DOCUMENTS</button>
	  	</div>
	  	<div>
	  		<button class="modal-button" (click)="openCamera(DocumentType.PHOTOS)">PHOTOS</button>
	  	</div>
	</mat-dialog-content>

	<mat-dialog-actions>
	  <div class="left-button-container">
	  	<button mat-dialog-close>CANCEL</button>
	  </div>
	</mat-dialog-actions>
</ng-template>

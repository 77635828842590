<div class="container">
  <div class="login-container">
    <img src="assets/oss_logo.png" alt="OSS Logo">
    <h1>Photo Capture</h1>
    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onLogin()">
  	  <mat-form-field appearance="fill" color="accent">
    	  <input matInput formControlName="username" type="text" placeholder="Username">
  	  </mat-form-field>

  	  <mat-form-field appearance="fill" color="accent">
   	  	 <input matInput formControlName="password" type="password" placeholder="Password">
 	    </mat-form-field>

 	    <button class="login-button" type="submit" [disabled]="submitting">Login</button>
    </form>
    <div class="status">
      <mat-progress-spinner *ngIf="submitting" mode="indeterminate" style="margin-top: 24px;" ></mat-progress-spinner>
    </div>
  </div>
  <div class="bottom-right">
    <span>Version: {{ appVersion }}</span>
  </div>
</div>

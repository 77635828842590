<div class="header">
  <button mat-icon-button class="back-button" (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span>{{ this.gallery?.id }}</span>
  <button mat-icon-button class="edit-button" (click)="editItemNumber()">
    <mat-icon>edit</mat-icon>
  </button>
  <app-network-status class="network-status"></app-network-status>
  <button mat-icon-button class="feedback-button" (click)="giveFeedback()">
    <mat-icon>comment</mat-icon>
  </button>
</div>
<div class="status">
  <mat-progress-spinner *ngIf="!gallery && !error" mode="indeterminate" style="margin: 24px auto;"></mat-progress-spinner>
</div>

<ng-container *ngIf="gallery && !isDestroying">
  <div class="gallery-group" *ngFor="let docGroup of regroupedImages; trackBy: trackByDocType">
    <ng-container *ngIf="docGroup">
      <div class="thumbnails">
        <ng-container *ngFor="let photoGroup of docGroup.groups; let groupIndex = index; trackBy: trackByGroup">
          <ng-container *ngIf="photoGroup.images.length > 0">
            <h2 class="title">{{photoGroup.label | titlecase}} - {{ docGroup.type | titlecase }}</h2>
            <div class="image-container" *ngFor="let imageInfo of photoGroup.images; let i = index; trackBy: trackById;"
                 (click)="openImage(imageInfo)">
              <ng-container *ngIf="!isDrawing">
                <div class="status new" *ngIf="imageInfo.status === ImageStatus.NEW">&#x2B24;</div>
                <div class="status uploaded" *ngIf="imageInfo.status === ImageStatus.UPLOADED ">&#x2713;</div>
                <div class="status error" *ngIf="imageInfo.status === ImageStatus.ERROR">&#x25B2;</div>
              </ng-container>
              <img [src]="imageInfo.thumbnailUrl ? imageInfo.thumbnailUrl : imageInfo.url" alt="Image {{ i + 1 }}" onerror="this.onerror=null; this.src='/assets/image-not-available.jpg';" />
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div *ngIf="regroupedImages.length === 0">
    <h1>No documents found</h1>
  </div>
</ng-container>
<ng-container *ngIf="error">
  <h1>{{error}}</h1>
</ng-container>

<div class="view-image-container" [ngStyle]="{'visibility': selectedImage ? 'visible' : 'hidden'}">
  <button mat-icon-button class="back-button" (click)="closeImage()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <p-carousel
    id="image-gallery"
    class="image-gallery"
    [value]="allImages"
    (onPage)="onCarouselPage()"
    [numVisible]="1"
    [numScroll]="1"
    [showNavigators]="false"
    [showIndicators]="false"
    #imageCarousel
  >
    <ng-template pTemplate="item" let-item>
      <div class="image-container">
        <img *ngIf="item" [src]="item.url" class="gallery-image" onerror="this.onerror=null; this.src='/assets/image-not-available.jpg';" />
      </div>

    </ng-template>
  </p-carousel>
</div>

import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {Location} from '@angular/common';
import {DocGroup, DocumentType, ImageInfo, ImageStatus, PhotoGroup, Report} from "../constants";
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {BrowserClient, Feedback, getClient} from '@sentry/angular-ivy'
import {ItemNumberModalComponent} from '../item-number-modal/item-number-modal.component';
import Panzoom from '@panzoom/panzoom';
import {draw} from "../helpers";
import {Store} from "@ngrx/store";
import {AppState} from "../+state/app/app.state";
import {getSelectedReport} from "../+state/report/report.selectors";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {FileService} from "../+state/file/file.service";
import * as Sentry from "@sentry/angular-ivy";
import {SentryErrorHandler} from "../auth/sentry-error-handler";


@UntilDestroy()
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnDestroy {
  @ViewChild('imageCarousel') imageCarousel: any;

  ImageStatus = ImageStatus;

  gallery: Report | undefined;

  panzoom: any;

  selectedImage = false;
  regroupedImages: DocGroup[] = []
  allImages: any[] = []
  indexChart = new Map<string, number>();
  error: string | undefined;
  hammerManager: any;

  isDestroying = false;

  // used to redraw status of pending uploaded files
  isDrawing = false;

  private imageCache: any = {};

  private zoomElements: any = [];

  constructor(
    private location: Location,
    private fileService: FileService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private sentryErrorHandler: SentryErrorHandler,
    ) {
      router.events.forEach((event) => {
        if(event instanceof NavigationStart) {
          if (event.navigationTrigger === 'popstate') {
            this.abortImageDownloads();
          }
        }
      });
  };

  async ngOnInit(): Promise<void> {
    this.store.select(getSelectedReport).pipe(untilDestroyed(this)).subscribe(report => {
      this.gallery = report;

      try {
        delete this.error;

        // reorg groups
        if (this.gallery) {
          const reorgGroups = {} as any;
          reorgGroups['scene'] = this.gallery.groups['scene'];
          Object.keys(this.gallery.groups)
            .filter(key => key !== 'scene')
            .sort()
            .forEach(key => {
              reorgGroups[key] = this.gallery!.groups[key];
            });

          //this.gallery.groups = reorgGroups;
          this.regroupedImages = this.getGroups()
          this.fillAll();
        }
      } catch (e) {
        this.error = 'Unable to load report.'
        this.sentryErrorHandler.handleError(e);
      }
    });

    delete this.error;

    if (!this.gallery) {
      await this.router.navigateByUrl('/');
    }

    if (this.imageCarousel) {
      this.imageCarousel.page = '-1';
    }
  }


  fillAll() {
    let i: number = 0;
    for (let docType of this.regroupedImages) {
      for (let group of docType.groups) {
        for (let image of group.images) {
          this.allImages.push(image);
          // map each image string to a number
          this.indexChart.set(image.key, i)
          i++;
        }
      }
    }
  }

  async openImage(image: ImageInfo) {
    this.selectedImage = true;
    this.imageCarousel.page = this.indexChart.get(image.key);

    // percentage of screen that must be swiped to trigger next/prev image
    const swipeThreshold = 0.5;
    this.imageCarousel.swipeThreshold = window.innerWidth * (1 - swipeThreshold);

    await this.onCarouselPage();
  }

  async onCarouselPage() {
    await draw();

    this.zoomElements.forEach((e: any) => e.destroy());
    this.zoomElements.length = 0;

    const imageCarouselImages = document.getElementsByClassName('gallery-image')
    for (let i = 0; i < imageCarouselImages.length; i++) {
      const zoom = Panzoom(imageCarouselImages[i] as any);
      zoom.setOptions({
        panOnlyWhenZoomed: true,
        pinchAndPan: true,
        minScale: 1,
      });

      this.zoomElements.push(zoom);
    }
  }

  goBack() {
    this.abortImageDownloads();
    this.location.back();
  }

  editItemNumber() {
    const dialogConfig = new MatDialogConfig();

    // Create a custom position strategy to center the dialog
    dialogConfig.position = {
      top: '20%',
      left: '10%',
    };
    dialogConfig.width = '350px';
    dialogConfig.data = {
      editNumberModal: true,
      oldItemNumber: this.gallery!.id,
    }

    const dialogRef = this.dialog.open(ItemNumberModalComponent, dialogConfig);
  }

  // Opens Sentry feedback widget
  giveFeedback() {
    const client = getClient<BrowserClient>();
    const feedback = client?.getIntegration(Feedback);

    // Don't render custom feedback button if Feedback integration isn't installed
    if (!feedback) {
      console.log('Error: Feedback integration not available.')
      return null;
    }

    feedback.openDialog();
    return;
  }

  closeImage() {
    this.selectedImage = false;
  }

  /**
   *
   * This function regroups the photos to be by document type
   * @returns DocGroup[] containing "documents" first and "photos" second
   */
  getGroups(): DocGroup[] {
    const response = [] as DocGroup[];
    const docTypes = Object.values(DocumentType);
    response.push({ //initialize array with empty docgroups
      type: DocumentType.DOCUMENTS,
      groups: []
    })
    response.push({
      type: DocumentType.PHOTOS,
      groups: []
    })

    if (this.gallery) {
      for (let docType of docTypes) {
        Object.keys(this.gallery.groups).sort().forEach(key => {
          let group: PhotoGroup = {
            label: key,
            images: new Array<ImageInfo>()
          }
          if ((this.gallery!.groups[key] as any) && (this.gallery!.groups[key] as any)[docType]) {
            group.images = [
              ...(Object.values((this.gallery!.groups[key] as any)[docType]) as ImageInfo[])
                .map((image: ImageInfo) => ({...image})),
            ];

            this.processImages(group.images)

            if (key === "scene") { // make sure scene appears first
              if (docType === DocumentType.DOCUMENTS) {
                response[0]['groups'].unshift(group)
              }
              if (docType === DocumentType.PHOTOS) {
                response[1]['groups'].unshift(group)
              }
            }

            else {
              if (docType === DocumentType.DOCUMENTS) {
                response[0]['groups'].push(group)
              }
              if (docType === DocumentType.PHOTOS) {
                response[1]['groups'].push(group)
              }
            }
          }
        });
      }
    }
    if (response[0]['groups'].length == 0 && response[1]['groups'].length == 0) {
      return [] // return empty array to indicate empty gallery. Simplifies template
    }
    return response;
  }

  // load unprocessed images from indexdb
  async processImages(images: ImageInfo[]) {
    const loadImagePromises: any = [];

    images.forEach((image, index) => {
      if (
        image.status === ImageStatus.NEW ||
        image.status === ImageStatus.ERROR
      ) {
        loadImagePromises.push(new Promise(async (resolve) => {
          let localFile;
          if (this.imageCache[image.key]) {
            localFile = this.imageCache[image.key];
          } else {
            localFile = await this.fileService.getFile(image.key);
            this.imageCache[image.key] = localFile;
          }

          if (localFile?.data) {
            images[index].url = localFile.data;
          }

          resolve(null);
        }));
      }
    });

    await Promise.all(loadImagePromises);
  }

  isEmpty(obj: Object): boolean {
    return Object.keys(obj).length === 0;
  }

  abortImageDownloads() {
    this.isDestroying = true;
  }

  trackByDocType(index:number, item:any) {
    return item.type;
  }

  trackByGroup(index:number, item:any) {
    return item.label;
  }

  trackById(index:number, item:any) {
    return item.key;
  }

  ngOnDestroy(): void {
    this.imageCache = {};
  }
}

<ng-container *ngIf="!available; else showPendingFiles">
  <button
    mat-icon-button class="network-unavailable"
    (click)="showStatus()">
    <mat-icon>cloud_off</mat-icon>
  </button>
</ng-container>

<ng-template #showPendingFiles>
  <button
    *ngIf="pendingFiles > 0"
    mat-icon-button class="upload-button"
    (click)="showPending()">
    <span class="pending-files">{{ pendingFiles }}</span>
  </button>
</ng-template>
